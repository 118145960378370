import { ArrowRight } from "@abnormalComponents/Icons";
import { Box, Button, Flex, Heading, VStack } from "@chakra-ui/react";
import parse from "html-react-parser";
import Link from "next/link";
import PageImage from "./PageImage";

const CallToActionBlock = ({ cta }) => {
	if (cta !== null) {
		const {
			bodyText,
			heading,
			homepageBladeColorScheme,
			image,
			target,
			eventDescription,
		} = cta;

		const colors = {
			"#000000": {
				// Black-01
				backgroundColor: "#000000",
				headingColor: "#B2F5E2",
				textColor: "#ffffff",
				buttonBackground: "#6863F2",
				buttonTextColor: "#ffffff",
			},
			"#002B49": {
				// Blue-01 (legacy)
				backgroundColor: "#11294F",
				headingColor: "#FFD595",
				textColor: "#ffffff",
				buttonBackground: "#A8EAE4",
				buttonTextColor: "#111111",
			},
			"#FEFAEB": {
				// Beige-04 (legacy)
				backgroundColor: "#EDEADF",
				headingColor: "#924E8C",
				textColor: "#0D1418",
				buttonBackground: "#924E8C",
				buttonTextColor: "#ffffff",
			},
			"#241544": {
				// Purple-Dark-05
				backgroundColor: "#241544",
				headingColor: "#B9BAED",
				textColor: "#ffffff",
				buttonBackground: "#B9BAED",
				buttonTextColor: "#000",
			},
		};

		const {
			headingColor,
			textColor,
			backgroundColor,
			buttonTextColor,
			buttonBackground,
		} = colors[homepageBladeColorScheme];

		const handleClick = (text, url) => {
			window.dataLayer?.push({
				event: "event",
				eventProps: {
					category: "Button Click",
					action: eventDescription ? eventDescription : text,
					label: url,
				},
			});
		};

		return (
			<Flex
				className="CallToAction"
				justify="center"
				w="full"
				px="5"
				bg={homepageBladeColorScheme}
			>
				<Flex
					className="CallToAction__container"
					align="center"
					gap={{ base: 5, md: "75px" }}
					w="full"
					maxW="1025px"
					py="sm"
					flexDir={{
						base: "column",
						md: "row",
					}}
				>
					<Flex
						className="CallToAction__content"
						width={{ base: "100%", md: "50%" }}
						flexDirection="column"
						gap={4}
						textAlign="left"
					>
						<Heading color={headingColor} as="h2" fontSize="24px">
							{heading}
            </Heading>
            {!!bodyText && (
              <Box
                className="CallToAction__text"
                fontSize="16px"
                color={textColor}
              >
                {parse(bodyText)}
              </Box>
            )}
						<Box className="CallToAction__button">
							<Button
								bg={buttonBackground}
								color={buttonTextColor}
								href={target?.url}
								as={Link}
								onClick={target && (() => handleClick(target.text, target.url))}
								rightIcon={<ArrowRight />}
								target={target?.target}
							>
								{target?.text}
							</Button>
						</Box>
					</Flex>
					<Flex
						className="CallToAction__image"
						justifyContent="end"
						width={{ base: "100%", md: "50%" }}
					>
						<PageImage
							horizontalAlignment={{ base: "left", md: "right" }}
							image={image[0]}
						/>
					</Flex>
				</Flex>
			</Flex>
		);
	}
};

export default CallToActionBlock;
